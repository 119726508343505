export default [
    {
        title: 'Wat houdt de Class Action Verhuurderheffing in?',
        text: 'De Class Action Verhuurderheffing is een procedure waarbij verhuurders één gezamenlijke procedure starten met als doel de onrechtmatigheid van de verhuurderheffing te laten uitspreken en deze maatregel onverbindend te laten verklaren. Het resultaat hiervan zal zijn dat de verhuurder geen verhuurderheffing meer hoeft te betalen en de verhuurderheffing die hij gedurende de procedure heeft betaald terugontvangt. '
    },
    {
        title: 'Wat is het voordeel voor ons als verhuurder om deel te nemen aan de Class Action Verhuurderheffing?',
        text: 'U betaalt jaarlijks een aanzienlijk bedrag aan verhuurderheffing. In onze visie betaalt u dit ten onrechte en heeft u daarom recht op teruggave van de door u betaalde verhuurderheffing. Procederen over de onrechtmatigheid van de verhuurderheffing is een complexe aangelegenheid. De wetgeving en met name haar achtergrond is ingewikkeld. Dit maakt procederen tegen de verhuurderheffing kostbaar. Voor de meeste verhuurders is het daarom niet de moeite waard zelfstandig een procedure te starten. Dit verandert echter indien de verhuurders zich verenigen in een collectief om samen tegen de verhuurderheffing een gerechtelijke procedure te starten. Met het collectief starten van een procedure, gaat er ook een veel sterker signaal uit naar de politiek om het beleid ten aanzien van de verhuurderheffing (en daarmee samenhangende maatregelen) te herzien. Juist omdat er over het beleid veel wordt gelobbyd, is dit een groot voordeel. '
    },
    {
        title: 'Kunnen alle eigenaren van sociale woningen meedoen?',
        text: 'Ja, alle verhuurders die verhuurderheffingsplichtig zijn kunnen meedoen. Dit zijn dus woningcorporaties, institutionele beleggers en particuliere beleggers met meer dan vijftig sociale huurwoningen. '
    },
    {
        title: 'Wat is een Class Action?',
        text: 'De class action is een beproefd model voor een collectieve rechtszaak. Meerdere partijen voeren gezamenlijk één procedure over een onderwerp dat hen allen aangaat, in dit geval de verhuurderheffing. '
    },
    {
        title: 'Kunt u mij vertellen welke argumenten bij de Class Action Verhuurderheffing worden aangevoerd?',
        text: 'Gedurende de eerste jaren van de Class Action Verhuurderheffing ( in september 2019 zijn wij gestart) hebben wij de argumenten voor ons zelf gehouden, oftewel hebben wij deze niet met de deelnemers gedeeld. De gedachte hierachter is dat moet worden voorkomen dat verhuurders zelfstandig – dus zonder deel te nemen aan de Class Action – met behulp van de argumenten procedures tegen de Verhuurderheffing gingen voeren. Hiermee zou afbreuk gedaan worden aan de voordelen van een collectieve procedure en zal de slagkracht ervan verminderen. Voor deelnemers die zich aan hebben gesloten bij de Class Action Verhuurderheffing bestaat de mogelijkheid om, onder voorwaarde van geheimhouding, voorafgaand aan aansluiting bij de Class Action Verhuurderheffing, kennis te nemen van de gronden. '
    },
    {
        title: 'Er is door aan aantal verhuurders al tegen de onrechtmatigheid van de Verhuurderheffing geprocedeerd. Dit zonder succes. Waarom vertrouwen jullie wél in een goede afloop?',
        text: 'Aan het opzetten van de Class Action Verhuurderheffing ligt een intensief onderzoek ten grondslag verricht door gespecialiseerde advocaten. Uit dit onderzoek is gebleken dat de Verhuurderheffing op bepaalde wezenlijke punten &#39;juridisch onhoudbaar&#39; is. Deze argumenten zijn in eerdere procedures over de onrechtmatigheid van de Verhuurderheffing, nog nooit aangevoerd. Voornoemde advocaten en de initiatiefnemers van de Class Action Verhuurderheffing zijn overtuigd dat met de gevonden argumenten de verhuurderheffing (uiteindelijk) door de rechter als onrechtmatig zal worden beoordeeld. '
    },
    {
        title: 'Wat is de doelstelling van de Class Action Verhuurderheffing?',
        text: 'Ons doel is het verkrijgen van een principiële uitspraak waarin de verhuurderheffing onrechtmatig wordt verklaard, oftewel een uitspraak die leidt tot definitieve afschaffing van de verhuurderheffing, zodat deze opbrengsten ten goede blijven van de sociale huursector, ten behoeve van het verduurzamen en bijbouwen van sociale huurwoningen. '
    },
    {
        title: 'Kan er ook worden geschikt?',
        text: 'Een schikking kan mogelijk een alternatief zijn ten opzichte van een definitieve uitspraak. Een schikking wordt alleen getroffen indien de Nederlandse Staat daartoe openstaat. Daarnaast kan alleen een schikking getroffen worden als de meerderheid van de deelnemers aan de Class Action met de schikking instemt. Hierbij worden aan deelnemers stemmen toegekend naar rato van het de verhuurderheffing die zij afdragen (hoe meer verhuurderheffing, hoe meer stemrecht). Het is dus aan de deelnemers of er uiteindelijk wordt geschikt of juist een principiële uitspraak wordt verkregen. '
    },
    {
        title: 'Hoe kunnen wij als verhuurder deelnemen aan de verhuurderheffing?',
        text: 'Deelname is eenvoudig. U vult op onze website uw contactgegevens in. U krijgt vervolgens een deelnameovereenkomst van ons toegezonden welke u ondertekend aan ons terugzendt. Ook hebben wij een kopie van uw aangifte verhuurderheffing over het betreffende jaar nodig. '
    },
    {
        title: 'Waar moet ik op letten bij het doen van aangifte en betaling van de verhuurderheffing, indien mijn organisatie deelname overweegt?',
        text: 'Uitgangspunt is dat u tijdig aangifte doet van de verhuurderheffing en deze ook tijdig aan de Belastingdienst betaalt. De uiterlijke dag hiervoor is 30 september van lopend jaar. Na betaling van de verhuurderheffing gaat de bezwaartermijn van zes weken lopen. '
    },
    {
        title: 'Tot wanneer kan mijn organisatie besluiten om deel te nemen aan de Class Action Verhuurderheffing?',
        text: 'Indien u wenst deel te nemen, dient u dit uiterlijk een week vóór het einde van uw bezwaartermijn aan ons kenbaar te maken. Voor ons is er dan voldoende tijd om voor u bezwaar in te dienen. In dit bezwaar zal worden aangesloten bij de reeds lopende, in 2019 gestarte collectieve procedure. '
    },
    {
        title: 'Wat als wij te laat hebben besloten om deel te nemen?',
        text: 'In dat geval kunt u besluiten om alsnog voor de aangifte verhuurderheffing van dit jaar mee te doen aan de procedure; wij verwachten dat de procedure dan nog loopt. Het nadeel voor u is dan wel, dat indien de procedure goed afloopt en u de verhuurderheffing terugbetaald krijgt, de door u betaalde verhuurderheffing voor voorgaande jaren buiten beschouwing wordt gelaten en dus niet aan u terug wordt betaald. Hoe eerder u besluit mee te doen, hoe meer verhuurderheffing u zal terugontvangen. '
    },
    {
        title: 'Hoe weet ik in welke stand van zaken de Class Action zich bevindt?',
        text: 'Alle ontwikkelingen worden vermeld op een alleen voor deelnemers toegankelijk gedeelte op onze website. Indien er een nieuw bericht op deze website is geplaatst, ontvangt u daarover een e-mail. Zo bent u altijd up-to-date. '
    },
    {
        title: 'Kan ik als deelnemende verhuurder bij zittingen aanwezig zijn?',
        text: 'Bij zittingen op de rechtbank, zittingen bij het Gerechtshof (hoger beroep) en bij de Hoge Raad (cassatie) kunt u aanwezig zijn. Deze zittingen zijn openbaar. '
    },
    {
        title: 'Hoe lang gaat de Class Action duren?',
        text: 'Dat is op voorhand niet te voorspellen. Juridische procedures nemen al gauw meerdere jaren in beslag, zeker wanneer het belang groot is. In het geval dat er de mogelijkheid voor een schikking ontstaat, kan de Class Action vervroegd eindigen. '
    },
    {
        title: 'Over welke jaren krijgen wij bij succesvolle afloop van de procedure de betaalde verhuurderheffing terug?',
        text: 'U krijgt bij succesvolle afloop de verhuurderheffing terugbetaald over de jaren die die procedure heeft voortgeduurd. Voorbeeld: indien u voor de verhuurderheffing 2022 tijdig deelneemt en uiteindelijk wordt in december 2025 de onrechtmatigheid van de verhuurderheffing door de rechter uitgesproken, dan krijgt u de betaalde verhuurderheffing over de jaren 2022, 2023, 2024 en 2025 terug. '
    },
    {
        title: 'Kan mijn organisatie, nadat is besloten om mee te doen aan de Class Action, de deelname stoppen?',
        text: 'U kunt ieder gewenst moment, zonder opgaaf van redenen en zonder kosten met de procedure stoppen. Hiermee vervalt wel voor u het ingestelde bezwaar, alsmede de procedure en de mogelijkheid op teruggaaf. '
    },
    {
        title: 'Hoe weet ik dat de reputatie van mijn organisatie door deelname niet beschadigd raakt?',
        text: 'De argumenten van de Class Action vinden hun basis in de wet van de verhuurderheffing. De grondslag van de Class Action is daarmee neutraal (iedere verhuurder kan een beroep op deze argumenten doen) en worden zoveel mogelijk los van de specifieke kenmerken van de deelnemers in de procedure gepresenteerd. Uiteraard onthouden wij ons in de procedure van taalgebruik en stellingen die de goede naam van onze deelnemers kunnen aantasten. '
    },
    {
        title: 'Zijn mijn gegevens bij deelneming veilig?',
        text: 'Ja, de gegevens van uw organisatie zijn veilig. De collectieve procedure wordt gevoerd door advocaten, die een geheimhoudingsplicht hebben. Daarnaast zien deze advocaten erop toe dat tussen deelnemende verhuurders geen informatie die op deelnemende verhuurders specifiek betrekking heeft, onnodig wordt gedeeld. '
    },
    {
        title: 'Wat houdt no cure – no pay bij de Class Action in?',
        text: 'No cure – no pay houdt in dat uw organisatie enkel een vergoeding voor deelname betaalt indien de Class Action succesvol is. Hieronder verstaan wij dat de verhuurderheffing vanwege een gerechtelijke uitspraak of schikking ophoudt te bestaan en de deelnemer betaalde verhuurderheffing terugontvangt. Indien de Class Action niet succesvol is, is uw organisatie geen vergoeding verschuldigd. '
    },
    {
        title: 'Wie draagt de kosten en het kostenrisico van de procedure?',
        text: 'Deze liggen bij Jeremia, de vennootschap achter de Class Action. Zij draagt alle juridische kosten en draagt ook de proceskostenveroordeling indien de deelnemers in de Class Action uiteindelijk in het ongelijk worden gesteld. '
    },
    {
        title: 'Waaruit bestaat de vergoeding van de deelnemers aan Jeremia?',
        text: 'Indien de Class Action tegen de verhuurderheffing succesvol is, zal aan de deelnemers vanaf het jaar waarin de deelname wordt gestart, de betaalde verhuurderheffing worden terugbetaald. Oftewel: Indien in 2020 de deelname wordt gestart en in 2025 volgt definitieve uitspraak, dan zal de deelnemer alle verhuurderheffing betaald in de jaren 2020, 2021, 2022, 2023, 2024 en 2025 terugontvangen van de Belastingdienst. Jeremia verlangt in dat geval een succesfee van 20%, berekend over de terugbetaalde verhuurderheffing, waarbij maximaal wordt uitgegaan van vier jaren. In het geval dat de deelnemer een substantieel bedrag aan verhuurderheffing betaalt, kunnen ten aanzien van de succesfee afwijkende afspraken worden gemaakt. '
    },
    {
        title: 'Is de succesfee in- of ex BTW?',
        text: 'De succesfee is inclusief BTW. '
    },
    {
        title: 'Met wie gaat de deelnemer een overeenkomst aan?',
        text: 'De deelnemer sluit een deelnameovereenkomst met Jeremia '
    },
    {
        title: 'Door welke juristen wordt de procedure gevoerd?',
        text: 'De procedure wordt gevoerd door mr. Z.H. van Dorth tot Medler (Van Dorth Advocaten). Voornoemde advocaat wordt ingehuurd door de initiatiefnemer Jeremia '
    },
    {
        title: 'Kunt u mij vertellen welke deelnemers er al meedoen?',
        text: 'Veel deelnemers hechten aan geheimhouding. Daarom kunnen wij niet zonder meer inzage geven in de deelnemers. Er zijn ook deelnemers die er geen moeite mee hebben dat hun deelname bekend wordt. Bij serieuze interesse kunnen wij over deze deelnemers meer informatie aan u verschaffen. '
    },
    {
        title: 'Wat wordt er gedaan om deelname aan de Class Action Verhuurderheffing passend te maken voor woningcorporaties?',
        text: 'Veel woningcorporaties hebben specifieke wensen ten aanzien van transparantie en inspraak. Op dit moment zijn wij de mogelijkheden aan het verkennen om meer aan deze wensen tegemoet te komen. '
    }
];
