<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">De verhuurderheffing verhindert de benodigde investeringen</h2>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-regular" style="line-height: 1.5;font-size: 20px">In het jaar 2013 is de verhuurderheffing in het leven geroepen: een ingrijpende
          belastingmaatregel die alleen aan verhuurders van sociale huurwoningen wordt
          opgelegd en door de sociale huurders wordt opgebracht. De verhuurderheffing
          was ooit bedoeld als crisismaatregel, om een gat in de Rijksbegroting te vullen. Al
          ligt deze periode al jaren achter ons, de heffing is er nog steeds.</h3>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/CA-1.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>Verhuurders van sociale huurwoningen betalen sinds 2013 verhuurderheffing aan
          de Belastingdienst. De omvang van de verhuurderheffing bedraagt jaarlijks circa
          € 2 miljard: een bedrag dat ten gunste komt aan de algemene middelen en
          onttrokken wordt aan de sociale huursector.</p>
        <p>De verhuurderheffing verhindert sindsdien de broodnodige investeringen in de
          sector waardoor het verduurzamen en bijbouwen van betaalbare woningen
          wordt vertraagd en het woningaanbod voor de mensen met lagere inkomens
          steeds nijpender wordt.</p>
        <p><strong>Class Action</strong></p>
        <p>Vanaf 2019 is er een substantiële groep van deelnemers binnen de Class Action
          geformeerd bestaande uit corporaties en private verhuurders van sociale
          huurwoningen. Deze groep is tot op heden gegroeid tot 56 deelnemingen. De
          totale omvang van verhuurderheffing (over de jaren 2019, 2020 en 2021) die met
          de Class Action gemoeid is bedraagt op dit moment rond de 125 miljoen euro. De
          succesfee, een percentage van dat bedrag, afhankelijk van de omvang van de
          verhuurderheffing die bij een positieve uitspraak van de rechter na
          terugontvangen verhuurderheffing door de deelnemers aan Jeremia wordt
          betaald bedraagt op dit moment 15 miljoen euro. Van dit bedrag zullen alle
          kosten voor de procedure, de proceskosten en de omzetbelasting afgedragen
          worden. De middelen die resteren worden door Jeremia geïnvesteerd in de ontwikkeling,
          de realisatie en de verduurzaming van sociale huurwoningen en maatschappelijk vastgoed.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
