<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Jeremia</h2>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/Missie.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>
          Voor de Class Action Verhuurderheffing is de onderneming Jeremia B.V.
          opgericht. Jeremia heeft ten doel een bijdrage te leveren aan het tekort van
          sociale huurwoningen, het verduurzamen daarvan en het investeren in
          maatschappelijk vastgoed, voornamelijk in de wijken van Rotterdam Zuid.
          Jeremia heeft een driehoofdige directie die bestaat uit; Petra Rutten, Paul John de
          Jong en Perry van Bekhoven, allen ook werkzaam bij Frame Vastgoed.
        </p>
        <p><strong>Activiteiten</strong></p>
        <p>
          Jeremia heeft ten doel te investeren in de ontwikkeling en realisatie van sociale huurwoningen en maatschappelijk vastgoed. Daarnaast werkt ze aan de vernieuwing van wijken, d.m.v. onderzoek en visieontwikkeling vaak in partnerships en worden maatschappelijke- en commerciële organisaties geadviseerd.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Jeremia"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
