<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Woningcorporaties</h2>
    <v-row>
      <v-col>
        <v-img :src="require('@/assets/CA2.jpg')" :aspect-ratio="16/10" ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="main-card">

        <p>Woningcorporaties betalen veruit het grootste deel van de verhuurderheffing.
          Om voor woningcorporaties deelname aan de Class Action te vereenvoudigen,
          zijn in 2020 in overleg met een tiental woningcorporaties de
          deelnamevoorwaarden aangepast. Daarnaast zijn, in overleg met een informele
          vertegenwoordiging van drie corporaties (de Kerngroep 1) er aanpassingen in de
          opzet van de procedure doorgevoerd, zodat die beter aansluit op
          woningcorporaties. Ook verdere coördinatie van de procedure geschiedt in
          afstemming en overleg met deze Kerngroep.</p>
        <p><strong>Model-deelnameovereenkomst</strong></p>
        <p>De Class Action Verhuurderheffing heeft samen met de Kerngroep de
          deelnameovereenkomst voor woningcorporaties opgesteld. Transparantie en
          inspraak gedurende de procedure is het uitgangspunt, de Kerngroep speelt
          daarin een belangrijke rol. Het model van de deelnameovereenkomst is <a :href="require('@/assets/overeenkomst.pdf')">hier</a> te
          downloaden. Voor deelname kan contact worden opgenomen via onderstaande
          gegevens.</p>
        <p><strong>Succesfee</strong></p>
        <p>
          De succesfee is de vergoeding die in geval van een succesvolle procedure, op
          basis van no-cure-no-pay, aan Jeremia verschuldigd is. Voor woningcorporaties
          wordt een aangepaste succesfee gehanteerd. Er zijn twee mogelijkheden: 10%
          succesfee over het hele terugbetaalde bedrag, of 20% over de eerste 2 miljoen
          en 5% over het resterende bedrag. De succesfee is gemaximeerd. Voor vragen
          hierover kunt u contact opnemen via onderstaande gegevens. Ook kan contact
          opgenomen worden met de leden van de Kerngroep.
        </p>
        <p>1) De Kerngroep bestaat op dit moment uit de volgende leden:</p>
        <ul>
          <li>Mevr. Wiepke van Erp Taalman Kip (bestuurder SWZ (Zwolle))</li>
          <li>Dhr. Arjan Deutekom (bestuurder GoedeStede (Almere))</li>
          <li>Dhr. Danny Visser (bestuurder Woonstichting SSW (Bilthoven))</li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Corporaties"
}
</script>

<style scoped>
.main-card p, .main-card a {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  line-height: 1.3;
  color: #56554F !important;
}
</style>
