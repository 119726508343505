<template>
  <v-container style="max-width: 900px">
    <h2 class="mb-6 mt-6">Deelnemen</h2>
    <v-row>
      <v-col cols="12">
        <p>
          Woningcorporaties betalen veruit het grootste deel van de verhuurderheffing. De Class Action Verhuurderheffing
          probeert de heffing aan te vechten, op nog niet eerder gebruikte unieke gronden. Om voor woningcorporaties
          deelname aan deze Class Action te vereenvoudigen, zijn we medio september 2020 in overleg getreden met een tiental
          woningcorporaties over onze vraag: “Wat moet er anders dan vorig jaar, om deelname voor corporaties mogelijk te
          maken?” In overleg met een informele vertegenwoordiging van vier corporaties uit deze groep (de Kerngroep 1 ) hebben
          we aanpassingen in de opzet van de collectieve procedure doorgevoerd, zodat die beter aansluit op de belangen van
          woningcorporaties. Ook verdere coördinatie van de procedure geschiedt in overleg met deze Kerngroep.
        </p>
        <h4>Transparantie over inhoud procedure</h4>
        <p>Een belangrijke wijziging is dat woningcorporaties een voorlopige deelnameovereenkomst kunnen sluiten. Als ze die
          hebben getekend, krijgen ze inzage in onze unieke juridische gronden van de procedure; Pas ná kennismaking daarvan beslissen corporaties
          definitief of ze willen meedoen. Zo niet, dan kunnen ze zonder gevolgen uit de procedure stappen. De enige verplichting
          die dan resteert, is strikte geheimhouding betreffende onze specifieke juridische gronden, tot aan de start zitting medio het tweede
          kwartaal van dit jaar.</p>
        <h4>Model-deelnameovereenkomst</h4>
        <p>
          De Class Action Verhuurderheffing heeft samen met de Kerngroep de deelnameovereenkomst opgesteld, aansluitend bij
          de behoeften van woningcorporaties. Deze bestaat uit twee fasen:
        </p>
        <ul>
          <li>de hiervoor genoemde voorlopige deelnameovereenkomst waarmee u kennis krijgt van de unieke
            gronden van de procedure; deze gaat over in een</li>
          <li>definitieve deelnameovereenkomst als u daarna besluit deel te nemen aan de procedure.</li>
        </ul>
        <p>
          Transparantie en inspraak voor woningcorporaties gedurende de gehele procedure is het uitgangspunt, de Kerngroep
          speelt daarin een grote rol. Het model van de deelnameovereenkomst kunt u <a :href="require('@/assets/Voorlopige-deelnameovereenkomst-1.pdf')" class="font-weight-bold">Hier</a> downloaden.
        </p>
        <h4>Succesfee gehalveerd</h4>
        <p>Voor woningcorporaties wordt een lagere succesfee gehanteerd dan eerder is gecommuniceerd (=de vergoeding die
          in geval van een succesvolle procedure aan de initiatiefnemer is verschuldigd). We bieden twee mogelijkheden: 10%
          succesfee over het hele terugbetaalde bedrag, of 20% over de eerste 2 miljoen en 5% over de rest. Daarbij wordt deze
          succesfee, bij het bereiken van een bepaalde omvang van de totale verhuurderheffing van de deelnemende corporaties,
          in beide varianten alsnog verlaagd tot maximaal 7% (in plaats van genoemde 10%) en 3% (in plaats van genoemde 5%).
          Met vragen hierover kunt u contact met ons opnemen.</p>
        <h4>Transparantie in de deelnemende woningcorporaties</h4>
        <p>
          Bent u als woningcorporatie serieus geïnteresseerd in deelname, maar wilt u weten welke andere woningcorporaties
          deelnemen? Neem ook dan contact met ons op. Uiteraard staat het u ook vrij om contact op te nemen met leden van de
          Kerngroep.
        </p>
        <p>
          1) De Kerngroep bestaat op dit moment uit de volgende leden:
        </p>
        <ul>
          <li>Mevr. Wiepke van Erp Taalman Kip (directeur-bestuurder van SWZ)</li>
          <li>Dhr. Kees van Kampen (directeur-bestuurde Oosterpoort)</li>
          <li>Dhr. Arjan Deutekom (bestuurder GoedeStede)</li>
          <li>Dhr. Danny Visser (bestuurder Woonstichting SSW)</li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Deelnemen"
}
</script>

<style scoped>

</style>
